import React from 'react';
import { Box, Container, Grid, Paper, Typography, IconButton, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ScienceIcon from '@mui/icons-material/Science';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { navigateToUrl } from 'single-spa';
import { GetAlertwestTheme } from "@digitalpath-inc/alertwest-components-react/theme";

const theme = GetAlertwestTheme({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const tiles = [
  { 
    title: 'AI Metrics', 
    icon: QueryStatsIcon, 
    path: '/ai-utils/metrics', 
    color: theme.palette.info.main // Blue for analytics/metrics
  },
  { 
    title: 'MLFlow', 
    icon: ScienceIcon, 
    path: '/ai-utils/mlflow', 
    color: theme.palette.secondary.main // Purple for ML/scientific tools
  },
  { 
    title: 'Propagation', 
    icon: SignalCellularAltIcon, 
    path: '/propagation', 
    color: theme.palette.warning.main // Orange for signal/network related
  },
  { 
    title: 'Alerts', 
    icon: NotificationsActiveIcon, 
    path: '/alerts', 
    color: theme.palette.error.main // Red for alerts/notifications
  },
];

function HomePage() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        {tiles.map((tile) => (
          <Grid item xs={12} sm={6} md={3} key={tile.title}>
            <StyledPaper
              elevation={2}
              onClick={() => navigateToUrl(tile.path)}
            >
              <IconButton
                sx={{
                  backgroundColor: tile.color,
                  color: theme.palette.common.white,
                  '&:hover': { backgroundColor: tile.color },
                  mb: 2,
                }}
                size="large"
              >
                <tile.icon />
              </IconButton>
              <Typography variant="h6" component="h2">
                {tile.title}
              </Typography>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default function Root(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh', py: 4 }}>
        <HomePage />
      </Box>
    </ThemeProvider>
  );
}
